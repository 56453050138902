
import { defineComponent } from "vue";

export default defineComponent({
    name: "BigCommerceRedirect",
    setup() {
        const originUrl = window.location.origin;
        const logo = "/media/logos/new-login-logo.svg";

        return {
            originUrl,
            logo,
        };
    },
});
